import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';

import Banner from '../../pics/be_a_devstar.jpg'


class PrivateSession extends React.Component {
	constructor(){
		super()
		this.enterHours = this.enterHours.bind(this)
	}
	state ={
		hours: 1,
		text: 'hour'
	}

	enterHours(e){
		let text
		let data = e.target.value || 1
		data < 1 ? data = 1 : data = data
		data > 1 ? text = 'hours' : text = 'hour'
		this.setState({hours:data, text: text })
	}

	render(){
		return (
			<div className="white grid-1-3"
			>
			<Helmet

			title="Private session"
			meta={[
				{ name: 'description', content: 'BCS Payments page' },
				{ name: 'keywords', content: 'payment form' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
				]}
				script={[
					{ "src": "https://js.stripe.com/v3/" }
					]}
					/>
					<div>
					<h1>Private session payment form</h1>
					</div>

					<div style={{
						marginTop: '2em',

					}}>
					<img 
					className='banners'
					src={Banner} 
					alt='Programming with JavaScript alumni sign up page'/>

					</div>





					<h2 id="cohorts">Access</h2>
					<div>
					<div className="event" >
					<h4>90€ per hour</h4>

					<span>
					<p>Please enter the number of hours booked</p>
					<div>
					<input 
					className='promoCode'
					type="number" 
					min='1'
					placeholder={"Enter the number of hours"} 
					onChange={this.enterHours}></input>
					</div>
					<p>You cost: {90*this.state.hours}€ for {this.state.hours} {this.state.text}</p>

					<Link to="/register" state={{course: `Private training, ${this.state.hours} ${this.state.text}`, date:'TBC', cost:90*this.state.hours}}>
					<button>Sign up</button>
					</Link>
					</span>
					</div>





					</div>




					</div>
					)
	}

}

export default PrivateSession
